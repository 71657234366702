import * as React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { useIsAuthenticated } from '../modules/auth/hooks';

export default function Page({
  children,
  kind,
}: {
  children: JSX.Element;
  kind?: 'protected' | 'public';
}) {
  if (kind === 'protected') {
    return <ProtectedPage>{children}</ProtectedPage>;
  }

  if (kind === 'public') {
    return <PublicPage>{children}</PublicPage>;
  }

  return children;
}

function ProtectedPage({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

function PublicPage({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
}
