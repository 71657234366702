import * as React from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import {
  Container,
  Button,
  Stack,
  Input,
  Text,
  InputGroup,
  Icon,
  InputRightElement,
  Alert,
  Link,
  useToast,
} from '@chakra-ui/react';
import { IoLockClosedOutline, IoMailOpenOutline } from 'react-icons/io5';
import * as yup from 'yup';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';

import Page from './Page';
import Header from './Header';
import { signUp } from '../api/auth';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const fields = [
  {
    name: 'Email',
    id: 'email',
    icon: IoMailOpenOutline,
    type: 'text',
  },
  {
    name: 'Password',
    id: 'password',
    icon: IoLockClosedOutline,
    type: 'password',
  },
  {
    name: 'Confirm Password',
    id: 'confirmPassword',
    icon: IoLockClosedOutline,
    type: 'password',
  },
];

export default function SignUpPage() {
  const [error, setError] = React.useState('');
  const { mutateAsync, isLoading } = useMutation('signIn', signUp);
  const navigate = useNavigate();
  const toast = useToast();

  const formik = useFormik({
    initialValues: { email: '', password: '', confirmPassword: '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await mutateAsync({
          email: values.email,
          password: values.password,
        });
        toast({
          title: `Confirmation email was sent to the "${values.email}" address`,
          isClosable: true,
          position: 'top-right',
          variant: 'solid',
          status: 'success',
          duration: 5000,
        });
        navigate('/signin');
      } catch (err: any) {
        setError(err.message);
      }
    },
  });

  return (
    <Page kind="public">
      <Container pt="10" minH="100%">
        <Header />

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            {fields.map((field) => (
              <SingleField key={field.id} {...field} formik={formik} />
            ))}

            {error && <Alert status="error">{error}</Alert>}

            <Button type="submit" data-name='signUpButton' isLoading={isLoading}>
              Sign Up
            </Button>

            <Text align="center">
              Already have an account?{' '}
              <Link as={ReactRouterLink} to="/signin">
                Sign In
              </Link>
            </Text>
          </Stack>
        </form>
      </Container>
    </Page>
  );
}

function SingleField({
  formik,
  id,
  name,
  type,
  icon,
}: {
  formik: any;
  id: string;
  name: string;
  type: string;
  icon: any;
}) {
  return (
    <Stack spacing={1}>
      <InputGroup>
        <Input
          placeholder={name}
          id={id}
          name={id}
          value={formik.values[id]}
          onChange={formik.handleChange}
          type={type}
          isInvalid={formik.touched[id] && !!formik.errors[id]}
        />
        <InputRightElement>
          <Icon as={icon} />
        </InputRightElement>
      </InputGroup>

      {formik.touched[id] && formik.errors[id] && (
        <Text fontSize="sm" color="red.500">
          {formik.errors[id]}
        </Text>
      )}
    </Stack>
  );
}
