import apiClient from './client';

type SignInResponse = {
  token: string;
  refreshToken: string;
  payload: {
    email: string;
    iat: number;
    exp: number;
  };
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<SignInResponse> => {
  const res = await apiClient.post('/auth/signin', {
    email,
    password,
  });
  return res.data;
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<SignInResponse> => {
  const res = await apiClient.post('/auth/signup', {
    email,
    password,
  });
  return res.data;
};

export const changePassword = async ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}): Promise<void> => {
  await apiClient.post('/auth/change-password', {
    oldPassword,
    newPassword,
  });
};

export const refreshToken = async ({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}): Promise<void> => {
  await apiClient.post('/auth/refresh', {
    token,
    refreshToken,
  });
};
