import * as React from 'react';
import {
  VStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useCopyToClipboard } from 'react-use';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { ColorModeSwitcher } from '../ColorModeSwitcher';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://clmp.me';

const QrPage = () => {
  const { shortCode } = useParams<{ shortCode: string }>();
  const url = `${BASE_URL}/u/${shortCode}`;

  const [, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  const onCopyClick = React.useCallback(() => {
    copyToClipboard(url);
    toast({
      title: 'Copied!',
      isClosable: true,
      position: 'top-right',
      status: 'success',
      duration: 5000,
    });
  }, [toast, url, copyToClipboard]);

  return (
    <VStack spacing={5} pt={6}>
      <ColorModeSwitcher justifySelf="flex-end" />
      <QRCode value={url} size={200} bgColor="#1a202c" fgColor="#fff" />

      <InputGroup size="md" w={280}>
        <Input readOnly value={url} />
        <InputRightElement>
          <IconButton
            aria-label="copy"
            icon={<CopyIcon />}
            onClick={onCopyClick}
          />
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
};

export default QrPage;
