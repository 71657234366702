import * as React from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import {
  Container,
  Button,
  Stack,
  Input,
  Text,
  InputGroup,
  Icon,
  InputRightElement,
  Alert,
  Link,
  useToast
} from '@chakra-ui/react';
import { IoLockClosedOutline, IoMailOpenOutline } from 'react-icons/io5';
import * as yup from 'yup';
import { useNavigate, useSearchParams, Link as ReactRouterLink } from 'react-router-dom';

import Page from './Page';
import Header from './Header';

import { useSetAuth } from '../modules/auth/hooks';

import { signIn } from '../api/auth';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const fields = [
  {
    name: 'Email',
    id: 'email',
    type: 'email',
    icon: IoMailOpenOutline,
  },
  {
    name: 'Password',
    id: 'password',
    type: 'password',
    icon: IoLockClosedOutline,
  },
];

export default function SignInPage() {
  const [error, setError] = React.useState('');
  const storeSignInData = useSetAuth();
  const { mutateAsync, isLoading } = useMutation('signIn', signIn);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const formik = useFormik({
    initialValues: { email: '', password: '', confirmPassword: '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await mutateAsync({
          email: values.email,
          password: values.password,
        });

        storeSignInData({
          token: res.token,
          refreshToken: res.refreshToken,
          isAuthenticated: true,
          payload: { email: res.payload.email },
        });

        navigate('/dashboard');
      } catch (err: any) {
        setError(err.message);
      }
    },
  });

  React.useEffect(() => {
    if (searchParams.get('emailConfirmed') === 'true') {
      toast({
        title: 'Email confirmed successfully!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 5000,
      });
    }
  }, [searchParams, toast])

  return (
    <Page kind="public">
      <Container pt="10" minH="100%">
        <Header />

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            {fields.map((field) => (
              <SingleField key={field.id} {...field} formik={formik} />
            ))}

            {error && <Alert status="error">{error}</Alert>}

            <Button type="submit" data-name="signIn" isLoading={isLoading}>
              Sign In
            </Button>

            <Text align="center">
              New member?{' '}
              <Link as={ReactRouterLink} to="/signup">
                Sign Up
              </Link>
            </Text>
          </Stack>
        </form>
      </Container>
    </Page>
  );
}

function SingleField({
  formik,
  id,
  type,
  name,
  icon,
}: {
  formik: any;
  id: string;
  type: string;
  name: string;
  icon: any;
}) {
  return (
    <Stack spacing={1}>
      <InputGroup>
        <Input
          placeholder={name}
          id={id}
          name={id}
          type={type}
          value={formik.values[id]}
          onChange={formik.handleChange}
          isInvalid={formik.touched[id] && !!formik.errors[id]}
        />
        <InputRightElement>
          <Icon as={icon} />
        </InputRightElement>
      </InputGroup>

      {formik.touched[id] && formik.errors[id] && (
        <Text fontSize="sm" color="red.500">
          {formik.errors[id]}
        </Text>
      )}
    </Stack>
  );
}
