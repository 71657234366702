import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import {
  Flex,
  Box,
  Divider,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Portal,
  Button,
  HStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, ArrowBackIcon } from '@chakra-ui/icons';

import { useAuthPayload, useClearAuth } from '../modules/auth/hooks';

import { ColorModeSwitcher } from '../ColorModeSwitcher';

export default function Header() {
  const authPayload = useAuthPayload();
  const clearAuth = useClearAuth();
  const isHomePage = useMatch('/');

  return (
    <Flex direction="row" justify="space-between" align="center" mb="6">
      <Box>
        <Link to="/">
          {!isHomePage && (
            <HStack>
              <ArrowBackIcon />
              <span>Home</span>
            </HStack>
          )}
        </Link>
      </Box>

      <Stack direction="row" h="40px" align="center">
        {authPayload?.email ? (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="ghost"
            >
              {authPayload.email}
            </MenuButton>
            <Portal>
              <MenuList zIndex={2}>
                <MenuItem as={Link} to="/dashboard">
                  Dashboard
                </MenuItem>
                <MenuItem as={Link} to="/settings">
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={clearAuth}>Sign out</MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        ) : (
          <Link to="/signin" data-name='signInHeader'>Sign in</Link>
        )}

        <Divider orientation="vertical" />
        <ColorModeSwitcher justifySelf="flex-end" />
      </Stack>
    </Flex>
  );
}
