import axios from 'axios';

import { clearLocalStorageAuth } from '../modules/auth/provider';
import { toast } from '../modules/toast';
const BASE_API_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001/' : '/';

const apiClient = axios.create({
  baseURL: BASE_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    config.headers = config.headers || {};
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalConfig = error.config;

    if (error.response) {
      if (error.response.data?.statusCode === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const response = await axios.post(`${BASE_API_URL}auth/refresh`, {
            accessToken: getTokensFromLocalStorage().token,
            refreshToken: getTokensFromLocalStorage().refreshToken,
          });
          const { token, refreshToken } = response.data;
          setTokensIntoLocalStorage({ token, refreshToken });
          return apiClient(originalConfig);
        } catch (error) {
          clearLocalStorageAuth();
          window.location.pathname = '/signin';
          return Promise.reject(error);
        }
      }

      showErrorToast(error.response.data?.message || 'Something went wrong.');
      return Promise.reject(error.response.data);
    } else {
      showErrorToast('Something went wrong.');
      Promise.reject(error);
    }
  },
);

function showErrorToast(message: string) {
  toast({
    title: message,
    isClosable: true,
    position: 'top-right',
    status: 'error',
    duration: 5000,
  });
}

function getTokensFromLocalStorage() {
  return {
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
  };
}

function setTokensIntoLocalStorage({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}) {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
}

export default apiClient;
