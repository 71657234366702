import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Center,
  Input,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { IoLockOpenOutline } from 'react-icons/io5';

const UnlockPage = () => {
  const [passcode, setPasscode] = React.useState('');

  const { shortCode } = useParams<{ shortCode: string }>();

  const onChangePasscode = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPasscode(event.target.value);
    },
    [],
  );

  const redirectToUrl = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      const encodedPasscode = btoa(passcode);
      const { protocol, host } = window.location;
      const href = `${protocol}//${host}/u/${shortCode}?auth=${encodedPasscode}`;
      window.location.replace(href);
    },
    [passcode, shortCode],
  );

  return (
    <Center mt="10">
      <Box p="5">
        <form onSubmit={redirectToUrl}>
          <InputGroup size="lg" w={250}>
            <Input
              placeholder="Enter passcode"
              onChange={onChangePasscode}
              value={passcode}
            />
            <InputRightElement>
              <IconButton
                aria-label="copy"
                size="lg"
                icon={<IoLockOpenOutline />}
                onClick={redirectToUrl}
              />
            </InputRightElement>
          </InputGroup>
        </form>
      </Box>
    </Center>
  );
};

export default UnlockPage;
