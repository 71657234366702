import * as React from 'react';
import ReactGA from 'react-ga';
import {
  Text,
  Icon,
  Flex,
  Tooltip,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { IoQrCodeOutline, IoCopyOutline } from 'react-icons/io5';
import { useCopyToClipboard } from 'react-use';

type Props = {
  urlCode: string;
  shortUrl: string;
};

const UrlResult = ({ urlCode, shortUrl }: Props) => {
  const [, copyToClipboard] = useCopyToClipboard();

  const onClickCopyButton = React.useCallback(() => {
    ReactGA.event({
      category: 'Copy Button',
      action: 'Clicked',
    });

    copyToClipboard(shortUrl);
  }, [shortUrl, copyToClipboard]);

  return (
    <Flex direction="column" align="center" mt="8">
      <Text fontSize="xl" fontWeight="300" mb="2">
        {shortUrl}
      </Text>

      <HStack spacing="2">
        <Tooltip label="Copy" placement="left">
          <IconButton
            aria-label="copy"
            icon={<IoCopyOutline />}
            onClick={onClickCopyButton}
          />
        </Tooltip>

        <Tooltip label="QR code" placement="right">
          <IconButton
            as="a"
            target="_blank"
            href={`/qr/${urlCode}`}
            aria-label="QR"
            icon={<Icon as={IoQrCodeOutline} />}
          />
        </Tooltip>
      </HStack>
    </Flex>
  );
};

export default UrlResult;
