import * as React from 'react';
import ReactGA from 'react-ga';

import {
  Container,
  Image,
  Flex,
  Link,
  Box,
  Center,
  Divider,
  Text,
  VStack,
} from '@chakra-ui/react';

import Header from './Header';

import ShortifyUrlField from './ShortifyUrlField';

import chromeStoreLogo from '../img/chrome-store-logo.png';

const HomePage = () => {
  return (
    <Container pt="10" minH="100%" as={Flex}>
      <Flex justifyContent="space-between" flex="1" flexDir="column">
        <Box width="100%">
          <Header />

          <ShortifyUrlField />
        </Box>

        <Center p="10" flexDir="column" textAlign="center">
          <VStack spacing="4">
            <Link
              onClick={() => {
                ReactGA.event({
                  category: 'Download button',
                  action: 'Clicked',
                });
              }}
              href="https://chrome.google.com/webstore/detail/clmpme/oekpmdfbdnandpaapfadacnbikccffmm"
              target="_blank"
            >
              <Image src={chromeStoreLogo} htmlWidth="200px" />
              <Text>Download Extension</Text>
            </Link>

            <Divider />

            <Text color="gray.500" fontSize="sm">
              Found Malicious URL?{' '}
              <Link
                variant="link"
                target="_blank"
                textDecor="underline"
                href="https://forms.gle/YopJtJ948NHr9yQd7"
              >
                Report Here
              </Link>
            </Text>
          </VStack>
        </Center>
      </Flex>
    </Container>
  );
};

export default HomePage;
