import React from 'react';

export type AuthData = {
  token?: string | null;
  refreshToken?: string | null;
  isAuthenticated?: boolean;
  payload?: { [key: string]: any } | null;
};

export const AuthContext = React.createContext<{
  authData: AuthData | null;
  updateAuthData: (data: AuthData) => void;
  clearAuthData: () => void;
}>({
  authData: {},
  updateAuthData: () => {},
  clearAuthData: () => {},
});

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [authData, setAuthData] = React.useState<AuthData | null>({
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    isAuthenticated: !!localStorage.getItem('isAuthenticated'),
    payload: JSON.parse(localStorage.getItem('payload') || '{}'),
  });

  const updateAuthData = React.useCallback((data: AuthData) => {
    setAuthData((prevState) => ({
      ...prevState,
      ...data,
    }));

    Object.keys(data).forEach((key) => {
      if (key === 'payload') {
        localStorage.setItem(key, JSON.stringify((data as any)[key]));
      } else {
        localStorage.setItem(key, (data as any)[key]);
      }
    });
  }, []);

  const clearAuthData = React.useCallback(() => {
    setAuthData(null);
    clearLocalStorageAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ authData, updateAuthData, clearAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export function clearLocalStorageAuth() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('payload');
  localStorage.removeItem('isAuthenticated');
}

export default AuthProvider;
