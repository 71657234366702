import { ChakraProvider, theme } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './HomePage';
import QrPage from './QrPage';
import NotFountPage from './NotFountPage';
import UnlockPage from './UnlockPage';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import SettingsPage from './SettingsPage';
import DashboardPage from './DashboardPage';

import queryClient from '../queryClient';

import '../styles/global.scss';

import { ToastContainer } from '../modules/toast';
import AuthProvider from '../modules/auth/provider';

export const App = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/qr/:shortCode" element={<QrPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/not-found" element={<NotFountPage />} />
            <Route path="/unlock/:shortCode" element={<UnlockPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  </AuthProvider>
);
