import apiClient from './client';
import { Url } from '../types/url.type';

export const fetchMyUrls = async ({
  skip = 0,
  limit = 10,
}: {
  skip: number;
  limit: number;
}): Promise<{ data: Array<Url>; count: number }> => {
  const res = await apiClient.get('/urls', { params: { skip, limit } });
  return res.data;
};

export const shortifyUrl = async ({
  url,
  passcode,
}: {
  url: string;
  passcode?: string;
}): Promise<Url> => {
  const { data } = await apiClient.post('/urls', {
    url,
    passcode,
  });
  return data;
};

export const toggleUrlStatus = async ({
  id,
  active,
}: {
  id: string;
  active: boolean;
}): Promise<Url> => {
  const { data } = await apiClient.put(`/urls/${id}`, {
    active,
  });

  return data;
};

export const deleteUrl = async ({ id }: { id: string }): Promise<Url> => {
  const { data } = await apiClient.delete(`/urls/${id}`);

  return data;
};
