import React from 'react';
import { Container } from '@chakra-ui/react';

import Header from './Header';
import Page from './Page';
import UrlTable from './UrlTable';

export default function DashboardPage() {
  return (
    <Page kind="protected">
      <Container py="10" minH="100%">
        <Header />
        <UrlTable />
      </Container>
    </Page>
  );
}
