import * as React from 'react';
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import ReactGA from 'react-ga';

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const themeKey = useColorModeValue('dark', 'light');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  const onClickToggleTheme = React.useCallback(() => {
    toggleColorMode();

    ReactGA.event({
      category: 'Theme',
      action: `Changed to ${themeKey}`,
    });
  }, [toggleColorMode, themeKey]);

  return (
    <IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color="current"
      onClick={onClickToggleTheme}
      icon={<SwitchIcon />}
      aria-label={`Switch to ${themeKey} mode`}
      {...props}
    />
  );
};
