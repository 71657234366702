import * as React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

const NotFountPage = () => {
  return (
    <Box pt={6}>
      <Flex justifyContent="center" alignItems="center" flex={1}>
        <Heading as="h2" size="lg">
          Url is removed or not found
        </Heading>
      </Flex>
    </Box>
  );
};

export default NotFountPage;
