import React from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Icon,
  Box,
  Stack,
  Skeleton,
  IconButton,
  Switch,
  useToast,
  HStack,
  Button,
} from '@chakra-ui/react';
import {
  IoQrCodeOutline,
  IoCopyOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { useCopyToClipboard } from 'react-use';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryFunctionContext,
} from 'react-query';

import { fetchMyUrls, toggleUrlStatus, deleteUrl } from '../api/url';

import { Url } from '../types/url.type';

const LIMIT = 10;

const UrlTable = () => {
  const [skip, setSkip] = React.useState<number>(0);

  const fetcher = React.useCallback(({ queryKey }: QueryFunctionContext) => {
    const skipParam = queryKey[1];
    return fetchMyUrls({ skip: skipParam as number, limit: LIMIT });
  }, []);

  const { data, isLoading } = useQuery(['myUrls', skip], fetcher);

  const [, copyToClipboard] = useCopyToClipboard();
  const toast = useToast();

  const onCopyClick = React.useCallback(
    (shortUrl: string) => {
      copyToClipboard(shortUrl);
      toast({
        title: 'Copied!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 5000,
      });
    },
    [toast, copyToClipboard],
  );

  const { data: items, count = 0 } = data || {};

  const pagination = React.useMemo(() => {
    const pagesCount = Math.ceil(count / LIMIT);
    if (pagesCount <= 1) {
      return null;
    }

    const buttons = [];

    for (let i = 0; i < pagesCount; i++) {
      const isActive = skip / LIMIT === i;
      buttons.push(
        <Button
          key={i}
          variant={isActive ? 'solid' : 'outline'}
          onClick={() => setSkip(i * LIMIT)}
        >
          {i + 1}
        </Button>,
      );
    }

    return (
      <HStack justifyContent="center" mt="5">
        {buttons}
      </HStack>
    );
  }, [count, skip]);

  if (isLoading) {
    return (
      <Box my={10} alignContent="center">
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      </Box>
    );
  }

  return (
    <Box mt={10}>
      <Text fontSize="2xl" mb={5} textAlign="center">
        My clmps
      </Text>

      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Url</Th>
            <Th>Visited</Th>
            <Th>Status</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {(items || []).map((url) => (
            <Row key={url.code} url={url} onCopyClick={onCopyClick} />
          ))}
        </Tbody>
      </Table>
      {pagination}
    </Box>
  );
};

function Row({
  url,
  onCopyClick,
}: {
  url: Url;
  onCopyClick: (shortUrl: string) => void;
}) {
  const queryClient = useQueryClient();
  const { mutate: mutateToggle } = useMutation(toggleUrlStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(['myUrls']);
    },
  });

  const { mutate: mutateDelete, isLoading: isRemoveLoading } = useMutation(
    deleteUrl,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['myUrls']);
      },
    },
  );

  const onStatusToggle = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      mutateToggle({ id: url._id, active: e.target.checked });
    },
    [mutateToggle, url._id],
  );

  const onRemoveClick = React.useCallback(() => {
    mutateDelete({ id: url._id });
  }, [mutateDelete, url._id]);

  return (
    <Tr>
      <Td>
        <Text fontSize="md" noOfLines={1} maxW="150px" title={url.longUrl}>
          {url.longUrl}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" noOfLines={1}>
          {url.visitedCount}
        </Text>
      </Td>
      <Td>
        <Switch onChange={onStatusToggle} isChecked={url.active} />
      </Td>
      <Td>
        <HStack dir="col">
          <IconButton
            aria-label="copy"
            icon={<IoCopyOutline />}
            onClick={() => onCopyClick(url.shortUrl)}
          />
          <IconButton
            as="a"
            target="_blank"
            href={`/qr/${url.code}`}
            aria-label="QR"
            icon={<Icon as={IoQrCodeOutline} />}
          />
          <IconButton
            isLoading={isRemoveLoading}
            onClick={onRemoveClick}
            aria-label="Delete Url"
            icon={<IoTrashOutline />}
          />
        </HStack>
      </Td>
    </Tr>
  );
}

export default UrlTable;
