import React from 'react';
import ReactGA from 'react-ga';
import {
  Box,
  Button,
  Input,
  InputGroup,
  Switch,
  FormControl,
  FormLabel,
  Stack,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import validator from 'validator';
import { useCopyToClipboard } from 'react-use';

import UrlResult from './UrlResult';

import { shortifyUrl } from '../api/url';

const ShortifyUrlField = () => {
  const toast = useToast();
  const [url, setUrl] = React.useState('');
  const [passcode, setPasscode] = React.useState('');
  const [isPasscodeEnabled, setIsPasscodeEnabled] = React.useState(false);
  const [urlCode, setUrlCode] = React.useState('');
  const [shortUrl, setShortUrl] = React.useState('');
  const [, copyToClipboard] = useCopyToClipboard();

  const onError = React.useCallback(
    (error: any) => {
      toast({
        title: error.message,
        isClosable: true,
        position: 'top-right',
        status: 'error',
        duration: 5000,
      });
    },
    [toast],
  );

  const onSuccess = React.useCallback(
    (data: any) => {
      copyToClipboard(data.shortUrl);
      setUrlCode(data.code);
      setShortUrl(data.shortUrl);
      // queryClient.invalidateQueries('latestUrls');
      setUrl('');
      setPasscode('');

      toast({
        title: 'Shortified successfully! Url automatically copied.',
        isClosable: true,
        position: 'top-right',
        status: 'success',
        duration: 5000,
      });
    },
    [toast, copyToClipboard],
  );

  const { mutate } = useMutation(shortifyUrl, {
    onError,
    onSuccess,
  });

  const onChangeUrl = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    },
    [],
  );

  const onChangePasscode = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPasscode(event.target.value);
    },
    [],
  );

  const onToggleIsPasscodeEnabled = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsPasscodeEnabled(event.target.checked);

      ReactGA.event({
        category: 'Passcode Toggle',
        action: event.target.checked ? 'Enabled' : 'Disabled',
      });
    },
    [],
  );

  const submit = React.useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      ReactGA.event({
        category: 'Shortify Button',
        action: 'Clicked',
      });

      if (!validator.isURL(url)) {
        return toast({
          title: 'Please fill valid url',
          isClosable: true,
          position: 'top-right',
          status: 'error',
          duration: 5000,
        });
      }

      if (isPasscodeEnabled && passcode.trim().length === 0) {
        return toast({
          title: 'Please fill passcode',
          isClosable: true,
          position: 'top-right',
          status: 'error',
          duration: 5000,
        });
      }

      mutate({ url, passcode: isPasscodeEnabled ? passcode : undefined });
    },
    [url, toast, isPasscodeEnabled, passcode, mutate],
  );

  return (
    <Box>
      <form onSubmit={submit}>
        <Stack spacing="2">
          <InputGroup>
            <Input
              pr={!isPasscodeEnabled ? '100' : undefined}
              borderRightRadius={isPasscodeEnabled ? '0' : undefined}
              placeholder="Enter url to shortify..."
              onChange={onChangeUrl}
              value={url}
              data-name='urlField'
            />

            {isPasscodeEnabled && (
              <Input
                pr="100"
                borderLeft="0"
                width="200"
                borderLeftRadius="0"
                placeholder="Passcode"
                onChange={onChangePasscode}
                value={passcode}
              />
            )}
            <InputRightElement width="100">
              <Button colorScheme="teal" onClick={submit}>
                Shortify
              </Button>
            </InputRightElement>
          </InputGroup>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="is-passcode-enabled" mb="0" fontWeight="300">
              Protect with passcode?
            </FormLabel>
            <Switch
              id="is-passcode-enabled"
              isChecked={isPasscodeEnabled}
              onChange={onToggleIsPasscodeEnabled}
            />
          </FormControl>
        </Stack>
      </form>

      {shortUrl && <UrlResult urlCode={urlCode} shortUrl={shortUrl} />}
    </Box>
  );
};

export default ShortifyUrlField;
