import * as React from 'react';
import { useFormik } from 'formik';
import {
  Container,
  Button,
  Stack,
  Input,
  Text,
  InputGroup,
  Icon,
  InputRightElement,
  Alert,
  useToast,
} from '@chakra-ui/react';
import { IoLockClosedOutline } from 'react-icons/io5';
import * as yup from 'yup';

import Page from './Page';
import Header from './Header';

import { changePassword } from '../api/auth';

const validationSchema = yup.object({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('new password is required'),
  confirmNewPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),
});

const fields = [
  {
    name: 'Current Password',
    id: 'oldPassword',
    icon: IoLockClosedOutline,
    type: 'password',
  },
  {
    name: 'New Password',
    id: 'newPassword',
    icon: IoLockClosedOutline,
    type: 'password',
  },
  {
    name: 'Confirm New Password',
    id: 'confirmNewPassword',
    icon: IoLockClosedOutline,
    type: 'password',
  },
];

export default function SettingsPage() {
  const [error, setError] = React.useState('');
  const toast = useToast();

  const formik = useFormik({
    initialValues: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await changePassword({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });

        toast({
          title: `you have successfully changed your password`,
          isClosable: true,
          position: 'top-right',
          variant: 'solid',
          status: 'success',
          duration: 5000,
        });
      } catch (err: any) {
        setError(err.message);
      }
    },
  });

  return (
    <Page kind="protected">
      <Container pt="10" minH="100%">
        <Header />

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            {fields.map((field) => (
              <SingleField key={field.id} {...field} formik={formik} />
            ))}

            {error && <Alert status="error">{error}</Alert>}

            <Button onClick={() => formik.handleSubmit()}>Confirm</Button>
          </Stack>
        </form>
      </Container>
    </Page>
  );
}

function SingleField({
  formik,
  id,
  name,
  type,
  icon,
}: {
  formik: any;
  id: string;
  name: string;
  type: string;
  icon: any;
}) {
  return (
    <Stack spacing={1}>
      <InputGroup>
        <Input
          placeholder={name}
          id={id}
          name={id}
          value={formik.values[id]}
          onChange={formik.handleChange}
          isInvalid={formik.touched[id] && !!formik.errors[id]}
          type={type}
        />
        <InputRightElement>
          <Icon as={icon} />
        </InputRightElement>
      </InputGroup>

      {formik.touched[id] && formik.errors[id] && (
        <Text fontSize="sm" color="red.500">
          {formik.errors[id]}
        </Text>
      )}
    </Stack>
  );
}
